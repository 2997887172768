import React, { useState } from "react"
import { graphql } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"
import LayoutGrid from "../components/layout-grid"
import Aside from "../components/aside"
import ActivistGridItem from "../components/activist-grid-item"
import FilterLawsuit from "../components/filter-lawsuit"
import FilterDeparments from "../components/filter-departments-honduras"
import { useMediaQuery } from 'react-responsive'
import Seo from "../components/seo"

const ActivistasHonduras = ({ data }) => {

  const allActivists = data.allActivistsJson.edges
  // los dos estados. Declarado el primer estado. Carga todos los items map.
  const [allItems, setHidden] = useState(allActivists)
  const dataFragments = data.dataJson
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })

  return (
    <LayoutGrid>
      <Seo
        title={dataFragments.fragment_2}
        description={dataFragments.fragment_4}
      />
      <Aside>
        <Box
          sx={{
            h1: {
              color: `honduras`,
            }
          }}
        >
          <Themed.h1>{dataFragments.fragment_2}</Themed.h1>
          <FilterLawsuit
            allActivistsFromMain={allActivists}
            color={`honduras`}
            setHiddenFromMainComponent={setHidden}
          />
          {isDesktopOrLaptop &&
            <FilterDeparments />
          }
        </Box>
      </Aside>
      <Grid
        as={`main`}
        columns={[2, 2, 3, 3, 4, 4]}
        gap={[2, 3]}
        sx={{
          maxWidth: `100vw !important`,
        }}
      >
        {allItems.map(({ node, index }) => (
          <ActivistGridItem
            bgcolor={`honduras`}
            key={node.id}
            value={node.id}
            illustrationimage={node.illustration.childImageSharp.gatsbyImageData}
            name={node.name}
            slug={node.slug}
            lawsuitspecific={node.lawsuit.specific}
          />
        ))}
      </Grid>
    </LayoutGrid>
  )
}

export default ActivistasHonduras

export const query = graphql`
  query ActivistasHonduras {
    allActivistsJson(filter: {country: {eq: "Honduras"}}, sort: {fields: name}) {
      edges {
        node {
          id
          name
          slug
          department
          departmentslug
          illustration {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          lawsuit {
            name
            slug
            specific
          }
        }
      }
    }
    dataJson(slug: {eq: "page-all-activists-by-country"}) {
      fragment_2
      fragment_4
    }
  }
`
